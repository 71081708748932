.proof-of-craft-popup_container {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    z-index: var(--proof-of-craft-popup-zIndex);
    border: 1px solid var(--dark-mode-300);
    overflow: hidden;
    border-radius: 0.25rem;
    background: var(--cu-black-700);
    padding: 0;
    gap: 0.5rem;

    .proof-of-craft-popup_inline-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding: 1rem;

        .close-btn {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: var(--cu-black-600);
            padding: 0.5rem;
            border-radius: 5rem;

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        .proof-file-controls-wrapper {
            position: absolute;
            bottom: 1rem;
            left: 50%;
            transform: translate(-50%, -0%);
            .control-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--cu-black-600);
                padding: 0.5rem;
                border-radius: 5rem;
            }
        }

        .proof-file-preview-section {
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;

            .proof-craft-file-box {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                border: 1px solid var(--cu-black-70);
                border-radius: 0.25rem;

                .pc_file-image,
                .pc_file-pdf,
                .pc_file-youtube {
                    width: 100%;
                    height: 100%;
                }

                .pc_file-image {
                    object-fit: contain;
                }

            }

            .proof-file-skelton-loader-wrapper {
                width: 100%;
                height: 100%;
                overflow: hidden;

                span {
                    display: flex;
                    width: 100%;
                    height: 105%;
                }
            }
        }

    }


}