.details-skeleton{
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    grid-template-rows: max-content;
    .skeleton-wrapper{
        height: fit-content;
        aspect-ratio: 1;
        span{
            &.flex{
                height: -moz-available;          /* WebKit-based browsers will ignore this. */
                height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                height: fill-available;
                height: 100%;
            }
        }
        .details-wrapper{
          .text-details{
            width: 100%;
            span{
                width: 100%;
            }
          }  
        }
    }
    &.aspect-pt-six{
        .skeleton-wrapper{
            aspect-ratio: 1/0.6;
        }  
    }
    &.aspect-unset{
        .skeleton-wrapper{
            aspect-ratio: unset;
        }
    }
}