.cu-table {
    &-responsive {
        @include scroll;
        white-space: nowrap;
    }

    table {
        width: 100%;

        th:first-child,
        td:first-child {
            position: sticky;
            left: 0;
            bottom: 0;
            z-index: 2;
            background-color: var(--cu-gray-850);
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                z-index: 4;
                background: linear-gradient(to bottom, var(--cu-gray-trans-200), transparent);
                pointer-events: none; 
            }
        }
        td:first-child {
            background-color: var(--cu-black-800);
        }

        thead {
            background: var(--cu-gray-850);

            th {
                font-weight: 600;
                padding-top: 1.64rem;
                padding-bottom: 1.64rem;


            }
        }

        th,
        td {
            color: var(--cu-gray-100);
            padding-left: 1rem;
            padding-right: 1rem;
        }

        td {

            @include hr-border(bottom);
            padding-top: 1.42rem;
            padding-bottom: 1.42rem;
        }
    }
}