.collection-sq-card {
    .nft-price {
        font-size: 0.875rem;
        color: var(--cu-black-5);
        font-weight: 500;
    }
    .flip-card {
      position: relative;
      aspect-ratio: 1;
      .flip-card-inner {
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
        transform-style: preserve-3d;
        .edit-delete-wrap{
          .icons-overlay-edit-delete{
              @media screen and (max-width:550px) {
                  padding: 0rem 0rem 1rem 1rem;
                  .icon-wrapper{
                      &.edit-icon{
                          svg{
                              path{
                                  stroke: var(--cu-white-200);
                              }
                          }
                      }
                      &.delete-icon{
                          svg{
                              path{
                                  fill: var(--cu-white-200);
                              }
                          }  
                      }
       
              }
              } 
          }
      }
        .flip-card-front{
          .edit-delete-wrap{
              display: none;
              background: transparent;
              @media screen and (max-width:550px) {
                  display: flex;
              }
          }
        }
        :is(.flip-card-front, .flip-card-back) {
          backface-visibility: hidden;
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .flip-card-back {
          transform: rotateY(180deg);
        }
      }
      &:hover {
        .flip-card-inner {
          transform: rotateY(180deg);
          .short-info-label{
              .images-lists{
                  .image-wrap{
                      z-index: unset;
                  }
              }
          }
        }
      }
      &.hide-flip  {
          &:hover {
              .flip-card-inner {
                transform: unset;
              //   .short-info-label{
              //     scale: 1;
              // }
              }
            }
      }
    }
    .own-collection{
        .edit-delete-wrapper{
            display: none;
            background: var(--cu-black-410);
       }
        &:hover{
            .edit-delete-wrapper{
                display: flex;
                width: 100%;
                height: 100%;
            }
        }
    }
    &.image-item {
        border-radius: 0.28571rem;
        // max-height: 25rem;
        // aspect-ratio: 1;
        .image-container{
            height: 100%;
        }
        .card-drop {
            border-radius: 0.25rem;
            border: 1px solid var(--cu-black-900);
            background: var(--cu-black-100);
        }
        .drop-name, .image-text {
            color: var(--cu-white);
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 0.85714rem;
            letter-spacing: 0.02rem;
        }
        .image-container {
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0.25rem;
            background-position: center;
            &.no-banner{
                background-size: contain;   
            }
            .edit-delete-wrap{
                display: flex;
                transition: 1s ease;
                justify-content: end;
                width: 100%;
                height: 100%;
                padding: 0.5rem;
              //   background: var(--cu-black-hover);
                .icons-overlay-edit-delete{
                    height: fit-content;
                }
            }
   
            opacity: 1;
            transition: background-image 1s ease-in-out;
            &:hover{
               transition: 1s ease-in-out;
            }
            // &:hover{
            //     opacity: 0.5;
            // }
            img.card-image {
                border-radius: 0px 0px 4px 4px;
                margin: auto;
            }
        }
        .image-text {
            font-weight: 500;
        }
        .community-pic {
            flex: 0 0 auto;
            align-self: flex-start;
            img {
                border-radius: 50%;
                border: 1px solid var(--cu-black-100);
                width: 2.25rem;
                height: 2.25rem;
                object-fit: cover;
                @media screen and (max-width: 550px){ 
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }
            
        }
        .community-name, .creator-name {
            color: var(--cu-black-5);
            font-size: .875rem;
            font-style: normal;
            font-weight: 500;
          //   line-height: .875rem;
            margin-top: -.25rem;
            text-align: left;
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: normal;
            @media screen and (max-width: 550px){ 
            font-size: 0.75rem;
          //   margin-top: 0rem;
          }
        }
        .collection-name {
            font-size: 1rem;
            word-break: break-word;
            color:var(--cu-white-200);
            @media screen and (max-width: 550px){ 
                padding-top: 0rem;
                font-size: 0.875rem !important;
                -webkit-line-clamp: 1;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: normal;
                max-width: 55dvw;
            }
        }
    }
    @media screen and (max-width: 550px){ 
        gap: 0.5rem;
    }
    .card-details {
        padding: 0.625rem 0.375rem;
        @media screen and (max-width: 550px){ 
            padding-top: 0;
            padding-bottom: 0;
        } 
    }
  }
  
  .tag-name {
   span{
    text-align: left;
    word-break: break-all;
    color:var(--cu-white-100) 
   }
  }
  
  .collection-container {
    .body
    .collection-sq-card{
        &.image-item{
            .image-container{
                aspect-ratio: 1;
            }
        }
    }
  }
  .short-info-label{
      gap: 0.5rem;
      align-items: center;
      font-size: 0.875rem;
      border-radius: 0.5rem;
      border-radius: 2rem;
      padding: 0.1875rem 0.625rem 0.1875rem 0.25rem;
      background: var(--cu-black-500);
      width: fit-content;
      margin: 0.5rem 0px 0px 0.5rem;  
      transition: all 0.5s ease; 
      @media screen and (max-width: 550px){ 
          font-size: 0.75rem;
          margin: 0.25rem 0px 0px 0.25rem;    
      }
  }
  