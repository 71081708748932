.attributes-tab {

    display: flex;
    flex-direction: column;
    gap: .625rem;
    width: 100%;

    flex: 1;
    position: relative;

    .attribute-details {
        width: 100%;
        flex: 1;
        overflow: hidden;
        position: relative;

        .attribute-details-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: .75rem;
            color: var(--cu-white);            

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            
            .details-sm {
                font-size: .5rem;

                .details-title-sm {
                    color: var(--cu-grey-600);
                }
            }            

            .details-description {
                color: var(--cu-white-700);
                text-align: left;
                word-break: break-all;
                font-size: 0.75rem;
            }
            .mr-0{
                margin-right: 0rem !important;
            }
            .pr-0{
                padding-right: 0rem !important;
            }
            .details-container {
                display: flex;
                flex-wrap: wrap;
            }

            .data-box {
                display: flex;
                flex-direction: column;
                border: 0.5px solid #30333a;
                border-top: none;
                border-left: none;
                border-bottom: none;
                padding: 0rem 1rem 0rem 0rem;
                margin-right: 0.75rem;
                margin-bottom: 0;
                .data-title {
                    text-align: right;
                    width: 5.625rem;
                    font-weight: 400;
                    font-size: 0.8125rem;
                    color: var(--cu-black-10);
                    margin-bottom:3px;
                    text-transform: capitalize;
                    text-wrap: wrap;
                    overflow-wrap: break-word;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    position: relative;
                    &[tooltip]:hover::after {
                        content: attr(tooltip);
                        position: fixed;
                        padding: 0.3125rem 0.75rem;
                        transform: translate(-36%, -94%);
                        font-size: 0.75rem;
                        border-radius: 0.75rem;
                        border:1px solid var(--dark-mode-300); ;
                        background: var(--Neutral-900, #0D0F11);
                        width: max-content;
                        margin-bottom: 5px;
                        display: inline-block;
                        z-index: 2;
                      }
                }
                .shapes-img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
                .data-value {
                    flex: 1;
                    word-break: break-word;
                    text-align: left;
                    font-weight: 400;
                    color: #F7F8F9;
                    font-size: 0.8125rem;
                    align-items: center;
                    margin-bottom:12px;
                }
                .data-subvalue {
                }

                .data-prop {
                }
            }                        

            .border-0{
                border: none !important;
            }


            .details-sm {
                font-size: 1rem;;

                ul.details-title-sm {
                    display: flex;
                    gap: .25rem;
                    list-style-type: none;
                    font-size: 1rem;

                    li {
                        color: var(--cu-blue-70);
                        font-weight: 600;

                        &::marker {
                            margin: 3rem;
                        }
                    }
                }
            }
        }
    }
    
}