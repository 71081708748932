.react-image-crop-container {
    .image-field {
        display: flex;
        flex-direction: column;
        gap: 0.375rem;

        .recommended-size {
            font-size: 0.75rem;
            font-weight: 400;
            color: var(--cu-white-100);
        }

        label {
            font-size: 0.75rem;
            font-weight: 400;
            color: var(--cu-white-100);
        }

        .image-wrapper {
            background: var(--cu-black-700);
            box-shadow: 0 0 0 0.2px var(--cu-black-100);

            label {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            .upload-icon {
                display: none;
                background-color: var(--cu-black-700);
                margin: auto;
                width: auto;
                height: auto;
            }

            .dummy-wrap {
                font-size: 0.875rem;
                color: var(--cu-white-100);
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .default-placeholder-image {
                img {
                    width: auto;
                    height: auto;
                }
            }

            .image-uploaded {
                padding: 0px;
                margin: auto;
                object-fit: cover;
                width: 100%;
                height: 100%;

                &.actual-img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 1.4rem;
                }
            }

            &:hover {
                label {
                    cursor: pointer;
                }
                .upload-icon {
                    display: inline;
                }

                .dummy-wrap,
                .image-uploaded {
                    display: none;
                }
            }

        }

        &.profile-image {
            .image-wrapper {
                width: 6.57143rem;
                height: 6.57143rem;
                border-radius: 5rem;
                overflow: hidden;

                .dummy-wrap {
                    .image-uploaded {
                        width: 100%;
                        height: 100%;
                    }
                }

                .image-uploaded {
                    max-height: 100%;
                    object-fit: cover;
                }

                .default-placeholder-image {
                    .image-uploaded {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }

        &.banner-image {
            .image-wrapper {
                border-radius: 0.75rem;
                aspect-ratio: var(--content-card-banner-img-aspect-ratio);
                overflow: hidden;
            }

        }

        &.thumbnail-image {
            .image-wrapper {
                aspect-ratio: 4 / 4 !important;
                background: var(--cu-black-700);
                overflow: hidden;
                box-shadow: 0 0 0 0.2px var(--cu-black-100);
                min-height: 9rem;
                max-height: 9rem;
                width: 14rem;
                max-width: 14rem;
                border-radius: 1.5rem;

                label {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .dummy-wrap {
                    p {
                        text-align: center;
                        font-size: 0.75rem;
                        color: var(--cu-black-10);
                    }

                }

            }
        }

        &.square-image {
            .image-wrapper {
                aspect-ratio: 1 !important;
                background: var(--cu-black-700);
                overflow: hidden;
                box-shadow: 0 0 0 0.2px var(--cu-black-100);
                border-radius: 1rem;
                min-height: unset;
                max-height: unset;
                width: unset;
                max-width: unset;

                label {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .dummy-wrap {
                    p {
                        text-align: center;
                        font-size: 0.75rem;
                        color: var(--cu-black-10);
                    }

                }
            }
        }
    }

}