.progress-bar-wrapper{ 
  position: fixed;
  background: var(--cu-white);
  bottom: 0;
  right: 2%;
  padding: 0.8rem;
  border-radius: 1rem 1rem 0 0;
  .progress-details-container { 
    min-width: 10rem;
    table {
      width: 100%;
      th {
        text-align: left;
      }
    }
  }
  .progress-state {
    color: var(--cu-blue);
    font-weight: 500;
  }
  .circular-progress-bar {
    position: relative;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .circular-svg {
    transform: rotate(-90deg); /* Rotate to start at the top */
  }
  
  .circular-bg {
    stroke-linecap: round;
  }
  
  .circular-fg {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s ease-in-out;
  }
  
  .circular-percentage {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    color: var(--cu-blue);
  }
}

  

