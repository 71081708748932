.small-info-section {
    .img-profile{
            border-radius: 50%;
            border: 1px solid var(--cu-black-100);
            width: 2.25rem;
            height: 2.25rem;
            object-fit: cover;
            @media screen and (max-width: 550px){ 
                width: 2rem;
                height: 2rem;
            }
    }
    .collection-name {
        font-size: 1rem;
        word-break: break-word;
        line-height: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 1rem;
        line-height: normal;
        overflow: hidden;
        text-align: left;
        white-space: normal;
        word-break: break-word;
        @media screen and (max-width: 550px) {
            padding-top: 0rem;
            max-width: 55dvw;
        }
    }

    .community-name {
        color: var(--cu-black-5);
        font-size: .875rem;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        line-height: normal;
        @media screen and (max-width: 550px) {
            font-size: 0.75rem !important;
        }

    }

    .asset-name {
        font-size: 0.875rem;
        color: var(--cu-black-5);
        font-weight: 500;
        line-height: normal;
        .short-heading{
            color: var(--cu-white-200);
        }
        @media screen and (max-width: 550px) {
            font-size: 0.75rem;
        }
    }
}