.piece_details_view-container {
    width: 90dvw;
    height: 82dvh;
    border: 1px solid var(--cu-black-100);
    background-color: var(--cu-black-900);
    border-radius: 8px;
    padding: 1rem 0;
}

.piece_main_details {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 1rem;

    .piece_main-image_box {
        width: 40%;
        aspect-ratio: 1;
        border-color: var(--cu-black-100);
    }

    .piece_details_box {
        width: 60%;

        .community_box {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1rem;
            color: var(--cu-black-5);
        }

        .pieces_description-details {
            display: flex;
            padding: 1rem 0 1rem 0rem;
            align-items: flex-start;
            gap: 1rem 0.57143rem;
            flex-wrap: wrap;

            .description-data {
                font-size: 0.85714rem;
                line-height: 1.21429rem;
                gap: 0.28571rem;
                border: 0.5px solid #30333a;
                border-top: none;
                border-left: none;
                border-bottom: none;
                text-align: left;

                .title {
                    color: var(--Neutral-300, #B8C0CC);
                    font-size: .875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.28px;
                }

                .data {
                    color: var(--Neutral-100, #E7EAEE);
                    font-family: ZenKaku;
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    span {
                        word-break: break-word;
                    }

                    img {
                        width: 1.14286rem;
                        height: 1.14286rem;
                    }
                }

                &:last-child {
                    border: unset;
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }


        .piece_information {
            display: flex;
            flex-direction: column;
            padding: 1rem 0 1rem 0rem;
            align-items: flex-start;
            gap: 1rem 0.57143rem;


            .info_header {
                color: #B8C0CC;
                font-size: .875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.28px;
            }

            .information-data {
                display: flex;
                flex-direction: column;
                font-size: 0.85714rem;
                line-height: 1.21429rem;
                padding: 0rem 0.57143rem 0rem 0rem;
                margin-right: 0.57143rem;
                gap: 0.5rem;
                text-align: left;

                @media screen and (max-width: 550px) {
                    font-size: 0.75rem;
                }

                .title {
                    width: 7rem;
                    text-align: right;
                    color: var(--cu-black-10);
                    font-size: 0.875rem;
                    text-overflow: ellipsis;
                    line-height: normal;
                    font-style: normal;
                    font-weight: 400;
                }

                .data {
                    color: var(--cu-white);
                    max-width: 100%;
                    font-size: 0.875rem;

                    img {
                        width: 1.14286rem;
                        height: 1.14286rem;
                    }
                }

                &:last-child {
                    border: unset;
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}