.asset-card-section {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    .action-icons{
        z-index: 1;
        display: flex;
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        .icon-list{
            display: none;
            &.active{
                display: flex;
            }
            @media screen and (max-width: 550px) {
                display: flex;
            }
        }
       
    }
    .image-with-loader-wrap{
        height: 100%;
         width: 100%;
        
        .image-wrap{
            height: 100%;    
        }
        .img-wrapper{
        //     overflow: hidden;
            width: 100%;
            height: 100%;
            img{
                object-fit: contain;
            }
        //     pointer-events: auto;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     position: relative;
      }
    }
    .asset-img {
        aspect-ratio: 1;
        border-radius: 0.375rem;
        border: 1px solid var(--cu-black-70);
        cursor: pointer;
        overflow: hidden;
        .info_icon-asset {
            left: 2%;
            position: absolute;
            top: 8px;
            transition: all .3s;
            z-index: 1;
            border-radius: var(--normal-border-radius);
            display: none;
            @media screen and (max-width: 550px) {
                display: block;
            }
            &.edit-delete-wrap {
                left: unset;
                right: 8px;
            }
        }
        .skeltonMainClass {
            left: 1px;
            right: 1px;
            bottom: 2px;
            top: -1px;
        }
        .nft-img-sec {
            border-radius: 0.28rem;
        }
        &.active-asset {
            border: 1px solid var(--cu-white);
        }
        &:hover {
            .info_icon-asset { 
                display: block;
            }
            .icon-list{
                display: flex;
            }
        }
       
                .set-as-thumbnail{
                    background: var(--cu-black-600);
                    border: 1px solid var(--cu-black-100);
                    padding: 0.25rem 0.625rem;
                    font-size: 0.5rem;
                    border-radius: var(--normal-border-radius);
                    color:var(--cu-white-200);
                    cursor: pointer;
                    line-height: normal;
                    &:hover{
                        background: var(--cu-black-150);
                    }
                }
            
        
    }
    .asset-name {
        font-size: 1rem;
        text-align: left;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        line-height: normal;
        word-break: break-word;
        @media screen and (max-width: 550px) {
            font-size: 0.875rem;
        }
    }
    .nft-price {
        font-size: 0.85rem;
        @media screen and (max-width: 550px) {
            font-size: 0.75rem;
          }
    }

    @media screen and (max-width: 550px) {
        .asset-img {
            .info-icon-set {
                display: block;
                 background-color: var(--cu-black-trans-200);
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
}