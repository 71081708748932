.textArea {
    background: var(--cu-gray-850);
    width: 100%;
    outline: none;
    padding: 1.07rem;
    font-size: 1rem;
    border-radius: 0.57rem;
    color: var(--cu-white);
    resize: none;
    &.lg,&.xl {
        height: 14.5rem !important;
        max-height: 14.5rem !important;
    }
}

.textArea:focus-within {
    border: 1px solid var(--cu-white);
}

.sub {
    font-size: 1rem;
    color: var(--cu-gray-900);
    font-style: italic;

}
.textarea-count {
    color: var(--cu-gray-100);
}