input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
}

.file {
    border: 2px solid var(--cu-blue);
    padding: .2em .4em;
    background: var(--cu-blue);
    border-radius: 0.28rem;
    color: var(--cu-white);
    font-size: 0.85rem;
    width: auto;

    &-value {
        word-break: break-all;
    }
}
input:disabled {
    cursor: not-allowed;
}
input[type=file]::file-selector-button {
    display: none;
}

.form-value-pre {
    text-wrap: nowrap;
}