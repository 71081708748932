.page-sub-title {
    font-size: 1.42rem;
    text-transform: capitalize;
    color: var(--cu-white);
}

.clamp_ellipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    white-space: normal;
}