.community-edit {
  min-width: 100%;
    width: 60rem;
    overflow: hidden !important;
    padding: 1rem 0;
    border: 1px solid var(--cu-black-100);
    background-color: var(--cu-black-900);
    border-radius: 0.5rem;
    .collection-list{
      .collection-container{
              grid-template-columns: repeat(4, 1fr);
      }
      .details-skeleton{
        grid-template-columns: repeat(4, 1fr);
      }
  }
  .selected-data-description{
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }
.content-wrapper{
  height: 65dvh;
  overflow-y: scroll;
  padding: 0rem 1rem 1rem 1rem;
}
  h2 {
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--cu-white);
  }
  .error-validation {
    font-size: 0.625rem;
  }
  .section-title {
    font-size: 1rem;
    font-weight: 400;
    color: var(--cu-white);
  }
  .brd-btm {
    border-bottom: 1px solid var(--cu-black-100);
    padding-bottom: 0.625rem;
  }
  label {
    font-size: 0.625rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }
  :is(input, textarea)::placeholder {
    color: var(--cu-black-10);
  }
  .form-box {
    max-height: 2rem;
    font-size:  0.625rem;
  }
  .selectbox-list {
    ul {
      li{
        font-size:0.625rem;
      }
  }
}
  .selectbox-label::after{
    width: 0.625rem;
    height: 0.625rem;
  }
  input,
  textarea {
    background-color: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    color: var(--cu-white-200);
    font-size: 0.625rem;
    max-height: 7rem;
    padding: 0.425rem;
    border-radius: 4px;
  }
  textarea {
    max-height: 4.3rem;
  }
  .upload-banner-image {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    height: 9.875rem;
    label {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .upload-icon {
      display: none;
      background-color: var(--cu-black-700);
      margin: auto;
    }
    .dummy-wrap{
      p{
        text-align: center;
        font-size: 0.75rem;
        color: var(--cu-black-10);
      }
    }
    .image-uploaded {
      padding: 0px;
      margin: auto;
      overflow: hidden;
      object-fit: contain;
      max-height: 9.875rem;
      height: 100%;
      &.actual-img{
        width: 100%;
        object-fit: initial;
      }
    }
    &.profile-img{
      width: 8.75rem;
      height: 8.75rem;
      border-radius: 5rem;
      overflow: hidden;
      .image-uploaded {
        max-height: 100%;
        object-fit: cover;
      }
    }
    &:hover {
      .upload-icon {
        display: inline;
      }
      .dummy-wrap,.image-uploaded {
        display: none;
      }
    }
  }
  .new-checkbox span {
    background: var(--cu-black-600);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    color: var(--cu-black-10);
    padding: 0.2rem 0.625rem;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &.active {
      border-color: var(--cu-blue-60);
    }
  }
  button {
    padding: 0.325rem 2.14rem;
    font-size: 0.825rem;
  }
  &.create {
    h3 {
      font-size: 1.375rem;
      font-weight: 600;
    }
    textarea {
      max-height: 33.5rem;
      resize: none;
      @media screen and (max-width: 550px) {
        max-height: 7.5rem;
      }
    }
    .new-checkbox span {
      color: var(--cu-white);
    }
    .sub-title {
      font-size: 0.75rem;
    }
  }
}
.community-collection{
:is(.details-skeleton,.collection-container){
  grid-template-columns: repeat(auto-fill, minmax(11.375em, 1fr));
  grid-template-rows: max-content;
}
}