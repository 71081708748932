.search {
  background: var(--cu-gray-850);

  &-icons {
    width: 1.32rem;
    height: 1.32rem;
  }

  &-input {
    padding: 0.9rem 1.85rem 0.9rem 1.71rem;
    border-radius: 0.42rem;
    &-box {
      width: 100%;
      background-color: transparent;
      color: var(--cu-gray-100);
      outline: none;
    }

    &-group {
      flex: 1;
    }
  }

  &-filter {
    max-width: 30rem;
    @media (max-width: 768px) {
      max-width: 16rem;
    }
  }

  &-date {
    width: 3.14rem;
    height: 3.14rem;
    justify-content: center;
    border-radius: 0.42rem;
    align-items: center;
  }
}

.mnu-active {
  color: var(--cu-blue) !important;
}

.menu-active {
  opacity: 1 !important;
}

.search-filter.btn-action {
  opacity: 0.4;
}

.search-filter {
  .dropdown-menu-list {
    background: var(--cu-gray-700);
    max-height: 30rem;
    overflow: auto;
    ::after {
      background: var(--cu-gray-700);
    }

    ul {
      li {
        font-size: 0.85rem;
      }
    }
    .apply {
      color: var(--cu-white);
      border-radius: 0.42rem;
      font-size: 0.85rem;
      display: flex;
      justify-content: center;
      padding: 0.2rem 0.31rem 0.2rem 0.31rem;
      background-color: var(--cu-blue);
      width: -webkit-fill-available;
      margin: 0rem 1.42rem 1.21rem 1.42rem;
    }
  }
}

.lengthy {
  .dropdown-menu-list {
    &::after {
      position: absolute;
      content: "";
      background-color: var(--cu-gray-700);
      clip-path: polygon(50% 0%, 55% 0%, 100% 100%, 0% 100%);
      width: 1rem;
      height: 1rem;
      top: -0.42rem;
      right: 0.42rem;
    }
    ul {
      &::after {
        content: none;
      }
      height: 19rem;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 12px;
      }
    }
  }
}
