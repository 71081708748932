.proof-of-craft_container {
    width: 100%;
    padding: 0 1rem;
    color: var(--cu-gray-980);
    height: 75dvh;
    min-height: 500px;
    overflow-y: scroll;
    
    .pc_upload-files-title {
        margin-bottom: 1rem;
    }
    .pc_files-cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14%, 1fr));
        gap: 2rem;

        .pc_file {
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 0.5rem;
            cursor: pointer;

            .yt-play_icon {
                width: 30%;
                position: absolute;
                top: 41%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }

            .pc_actions-wrapper {
                position: absolute;
                top: 0;
                display: flex;
                justify-content: flex-end;
                background-color: var(--cu-black-trans-200);
                z-index: -1;
                transition: all 0.25s;
                opacity: 0;

                .pc_close-icon {
                    width: fit-content;
                    height: fit-content;
                    padding: 6px;
                }
            }

            .pc_file-image {
                object-fit: contain;
                border: 1px solid var(--cu-gray-900);
                padding: 0.5rem;
            }

            .pc_file-image,
            .pc_actions-wrapper {
                width: 100%;
                aspect-ratio: 1;
                border-radius: 12px;
            }

            p {
                font-size: 0.875rem;
                z-index: 3;
                -webkit-line-clamp: 1;
            }

            &:hover {
                .pc_actions-wrapper {
                    z-index: 2;
                    opacity: 1;
                }
            }
        }
    }
}