
.common_style-tab-container {
    .tab-data {
        display: flex;
        gap: 1.4rem;
        border-bottom: solid 1px var(--cu-black-10);
        box-sizing: border-box;
        cursor: pointer;
        color: var(--cu-black-5);

        .tab {
            font-size: .875rem;
            line-height: 1.21429rem;
            padding-bottom: .5rem;
            box-sizing: border-box;
            position: relative;
            display: flex;
            gap: 0.25rem;
            border-bottom: solid 2px transparent;

            &.not-active {
                &::after {
                    background: none repeat scroll 0 0 transparent;
                    bottom: -1px;
                    content: "";
                    display: block;
                    height: 1px;
                    left: 50%;
                    position: absolute;
                    background: var(--cu-white-200);
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                }

                &:hover {
                    color: var(--cu-white-200);

                    &::after {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            &.active {
                color: var(--cu-white-200);
                border-bottom: solid 2px var(--cu-white-200);

            }
        }

    }
    @media screen and (max-width: 550px) {

        &.tab-end-gap-iOS {
            // for Safari iOS
            @supports (-webkit-touch-callout: none) {
                width: calc(100% - 1rem);
            }
        }

        .tab-data {
            overflow-x: auto;
            gap: 1rem;
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
                /* Safari and Chrome */
            }

            .tab {
                white-space: nowrap;

                &:last-child {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}


.common_style-tab-container {
    &.tab-type-4 {
        .tab-data{
            border-bottom: none;
            display: flex;
            gap: 3rem;
            @media screen and (max-width: 550px) {
                gap: 1.5rem;
            }
        }
        .tab {
            padding: 0.5rem 1rem 0.5rem 0.5rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            border-radius: 0.5rem;
            border-bottom: none;
            min-width: 14.5rem;
            font-size: 1rem;
            background: var(--cu-black-400);
            &::after {
                content: none !important;
            }
            .img-wrap {
                width: 3.75rem;
                aspect-ratio: 1;
                border-radius: 0.5rem;
                overflow: hidden;
                img{
                width: 100%;
                height: 100%;     }
            }

            &:is(.active,&:hover) {
                border-bottom: none; 
                background: var(--cu-black-50);
            }
        }
    }

    @media screen and (max-width: 550px) {
        &.tab-type-3 {
            width: 100%;

            .tab-data {
                width: 100%;

                .tab {
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    &:last-child {
                        margin-right: 0rem;
                    }
                }
            }
        }
    }
}