.edit-collection {
  min-width: 100%;
  width: 60rem;
  // overflow: hidden !important;
  padding: 1rem 0;
  border: 1px solid var(--cu-black-100);
  background-color: var(--cu-black-900);
  border-radius: 8px;
max-height: 93dvh;
.selected-data-description{
  font-size: 0.75rem;
  color: var(--cu-white-200);
}
.pieces-total-data-list{
  height: 60dvh;
  overflow: auto;
  padding: 0rem 1rem;
}
&.editing-collection{
.details-main-container{
  max-height: 70dvh;
}
.pieces-total-data-list{
  height: 77dvh;
}
}
.socials-wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
  .popup-wrapper {
    max-width: 80%;
    max-height: 80%;
    .content {
      white-space: pre-wrap;
      font-size: 1rem;      
    }
  }
  .tab-container-details {
    padding: 1rem;

    .tab-data {
      display: flex;
      gap: 2rem;
      border-bottom: solid 1px var(--cu-black-10);
      ;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--cu-black-10);
      font-size: .875rem;
      line-height: 1.21429rem;

      .tab {
        font-size: .875rem;
        line-height: 1.21429rem;
        padding-bottom: .625rem;
        box-sizing: border-box;
        position: relative;

        &.not-active {
          &::after {
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            background: var(--cu-white-200);
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }

          &:hover {
            color: var(--cu-white-200);

            &::after {
              width: 100%;
              left: 0;
            }
          }
        }

        &.active {
          color: var(--cu-white-200);
          border-bottom: solid 2px var(--cu-white-200);

        }
      }

    }
  }
  .details-main-container {
    overflow-y: scroll;
    max-height: 53dvh;
    // min-height: 40rem;
    padding: 0 0 0 1rem;
  .thumbnail_image {
    .label-text {
      font-size: 0.875rem;
      color: white;
    }
    .upload-banner-image {
      label {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .upload-icon {
        display: none;
        background-color: var(--cu-black-700);
        margin: auto;
      }
      .dummy-wrap {
        p {
          text-align: center;
          font-size: 0.75rem;
          color: var(--cu-black-10);
        }
      }
      .image-uploaded, .image-uploaded-collage {
        border: 1px solid var(--cu-black-100);
        border-radius: 18px;
        overflow: hidden;
        padding: 0px;
        margin: auto;
        overflow: hidden;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        aspect-ratio: 4 / 4;
        &.actual-img, 
        &.actual-img-collage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.profile-img, &.profile-img .react-loading-skeleton {
        width: 100%;
        height: 100%;
        .image-uploaded.actual-img {
          max-height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      &.profile-img .react-loading-skeleton {
        position: relative;
        top:-1px;
      }
      &:hover {
        .upload-icon {
          display: inline;
        }
        .dummy-wrap,
        .image-uploaded {
          display: none;
        }
      }
    }
  }
}
  .collection {
    h2 {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .section-title {
      font-size: 1rem;
      font-weight: 400;
      color: var(--cu-white);
    }
    .error-validation {
      font-size: 0.625rem;
    }
    .brd-btm {
      border-bottom: 1px solid var(--cu-black-100);
      padding-bottom: 0.625rem;
    }
    label {
      font-size: 0.625rem;
      font-weight: 400;
      color: var(--cu-white-100);
    }
    :is(input, textarea)::placeholder {
      color: var(--cu-black-10);
    }
    .form-box {
      max-height: 2rem;
      font-size: 0.625rem;
    }
    .selectbox-list {
      ul {
        li {
          font-size: 0.625rem;
        }
      }
    }
    .selectbox-label::after {
      width: 0.625rem;
      height: 0.625rem;
    }
    input,
    textarea {
      background-color: var(--cu-black-700);
      // border: 1px solid var(--cu-black-100);
      color: var(--cu-white-200);
      font-size: 0.625rem;
      max-height: 7rem;
      padding: 0.425rem;
      border-radius: 4px;
      &::placeholder {
        color: var(--cu-black-10);
      }
    }
    textarea {
      border: 1px solid var(--cu-black-100);
    }
    :is(input, textarea)::placeholder {
      color: var(--cu-black-10);
    }
    textarea {
      max-height: 15rem;
    }
    .formControl,
    .autoCom ul>li {
       font-size: 0.625rem;
       color:var(--cu-white-200);
    }
    .upload-banner-image {
      background: var(--cu-black-700);
      border: 1px solid var(--cu-black-100);
      border-radius: 4px;
      height: 10.25rem;
      label {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .upload-icon {
        display: none;
        background-color: var(--cu-black-700);
        margin: auto;
      }
      .dummy-wrap {
        p {
          text-align: center;
          font-size: 0.75rem;
          color: var(--cu-black-10);
        }
      }
      .image-uploaded {
        padding: 0px;
        margin: auto;
        overflow: hidden;
        object-fit: cover;
        max-height: 10.25rem;
        height: 100%;
        &.actual-img {
          width: 100%;
          object-fit: cover !important;
        }
      }
      &.profile-img, &.profile-img .react-loading-skeleton {
        width: 10.375rem;
        height: 10.25rem;
        .image-uploaded.actual-img {
          max-height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      &.profile-img .react-loading-skeleton {
        position: relative;
        top:-1px;
      }
      &:hover {
        .upload-icon {
          display: inline;
        }
        .dummy-wrap,
        .image-uploaded {
          display: none;
        }
      }
    }
    .new-checkbox span {
      background: var(--cu-black-600);
      border: 1px solid var(--cu-black-100);
      border-radius: 4px;
      color: var(--cu-black-10);
      padding: 0.2rem 0.625rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &.active {
        border-color: var(--cu-blue-60);
      }
    }
    .common_button {
      background: var(--cu-black-600);
      border: 1px solid var(--cu-black-100);
      border-radius: 4px;
      color: var(--cu-black-10);
      padding: 0.2rem 0.625rem;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      &.active {
        border-color: var(--cu-blue-60);
      }
    }
    button {
      padding: 0.325rem 2.14rem;
      font-size: 0.825rem;
    }
    &.create {
      h3 {
        font-size: 1.375rem;
        font-weight: 600;
      }
      textarea {
        max-height: 33.5rem;
        resize: none;
        @media screen and (max-width: 550px) {
          max-height: 7.5rem;
        }
      }
      .new-checkbox span {
        color: var(--cu-white);
      }
      .sub-title {
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: 550px) {
      &.create {
        .button {
          width: 100%;
        }
      }
    }
    .foot-text {
      font-size: 1rem;
      color: var(--cu-black-5);
      line-height: 1.21429rem;
    }
    .primary-link-icon {
      width: 2rem;
      height: 2rem;
      background: var(--cu-black-110);
      padding: 0.5rem;
      margin-top: 16px;
      border-radius: 6px;
    }
  }

  h2 {
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--cu-white);
  }
  .banner {
    flex-wrap: wrap;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    gap: 1.06rem;
    .image-section {
      grid-template-columns: repeat(auto-fill, minmax(15.5rem, 1fr));
      gap: 1.06rem;
      max-width: 32.75rem;
      @media (max-width: 768px) {
        max-width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
      }
      .img-box {
        border-radius: 0.25rem;
        border: 1px solid var(--cu-black-100);
        background: var(--cu-black-600);
        box-shadow: 0px 0.375rem 0.75rem 0px rgba(0, 0, 0, 0.1);
        height: 7.3rem;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .details-section {
      border-radius: 0.375rem;
      border: 1px solid var(--cu-black-100);
      background: var(--cu-black-900);
      padding: 1.375rem;
      gap: 0.625rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 250px;
      .reload-section {
        width: 100%;
        height: 0.8125rem;
      }
      .details-box {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        .title {
          color: var(--cu-blue-70);
          font-size: 0.875rem;
          font-weight: 600;
          min-width: 7.5rem;
        }
        .content {
          color: var(--cu-white-200);
          font-size: 0.875rem;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 768px) {
            max-width: 8.75rem;
          }
        }
      }
    }
  }
  .community-list-container {
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 0.71429rem;
      color: #fff;
      &.active {
        padding: 10px;
        border: 2px solid;
        border-radius: 4px;
      }

      .image-container {
        border-radius: 6.57143rem;
        object-fit: cover;

        img {
          width: 6.57143rem;
          height: 6.57143rem;
          border-radius: 6.57143rem;
          object-fit: cover;
        }
      }

      .title-container {
        font-weight: 500;
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 5.75rem;
        line-height: 0.85714rem;
      }
    }
  }
}

.savetag {
  width: 8rem;
  margin-left: 1rem;
  padding: 0 !important;
  height: 1.8rem;
  margin-top: 0.3rem;
  border-radius: 0.35rem;
}
.buttons_container {
  button {
    font-size: 0.8rem;
    padding: 0.5rem 2rem;
  }
}