@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'base/typography';
@import 'layout/layout';
@import 'layout/navigation';
@import 'font/font';
@import 'components/buttons';
@import 'components/forms';
@import 'components/text';
@import 'components/card';
@import 'components/spinner';
@import 'components/scrollbar';
@import 'components/tabs';
@import 'components/table';
@import 'components/sidenav';
@import 'components/popup';
@import 'themes/colors';
@import 'themes/default';
@import 'themes/dynamicVariable';
@import 'animation/animation';
@import 'components/reactCropUI';
@import 'components/general';