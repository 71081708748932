.creator-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48.5rem;
    gap: 1rem;
    background: #191D23;
    padding-bottom: 0.75rem;
    border-radius: var(--normal-border-radius);
    overflow: hidden;
    border: 1px;
    height: 25.35rem;
    position: absolute;
    opacity: 0;
    .head-section {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-bottom: 3px;
        margin-left: -1px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0.6px;
            bottom: -15px;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.80) 20%, rgba(0, 0, 0, 0.1) 67%);
        }
        .cover-image {
            width: 100%;
            padding: 1rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 6.5rem;
            padding-bottom: 2px;
        }
        .profile-image {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            img {
                object-fit: cover;
                border-radius: 50%;
                background: var(--cu-black-500);
                width: 6rem;
                height: 6rem;
                border: 1px solid var(--cu-black-400);
            }
        }
        .blockchain-section {
            .blockchain-container {
                display: flex;
                align-items: center;
            }
            .blockchain-image {
                width: 2rem;
                height: 2rem;
                .count-text {
                    position: absolute;
                    inset: 0;
                    background: rgba(0, 0, 0, 0.4117647059);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #E7EAEE;
                    border-radius: 1rem;
                }
            }
        }
        .creator-name {
            display: flex;
            align-items: center;
            width: 75%;
            height: 100%;
            padding-bottom: 2rem;
            p { 
                font-size: 2rem;
                color: #E7EAEE;
                text-align: left;
                font-weight: 500;
            }
        }
    }
    .body-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .attributes-section {
            display: flex;
            align-items: center;
            padding-right: 1rem;
            border-right: 1rem;
            border-color: red;
            justify-content: start;
            .attributes-container {
                display: flex;
                gap: 1rem;
                label {
                    font-size: 1rem;
                    color: var(--cu-white-100);
                }
                .value {
                    color: var(--cu-white-200);
                }
            }            
        }
        .collection-image-container {
            width: 100%;
            gap: 1rem;
            display: grid !important;
            grid-template-columns: repeat(4, 1fr) !important;
            .collection-image {
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1;
                img {
                    border-radius: var(--normal-border-radius);
                    object-fit: cover;
                }
                .count-text {
                    position: absolute;
                    inset: 0;
                    background: #00000069;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #E7EAEE;
                    border-radius: var(--normal-border-radius);
                    .count-container {
                        border-radius: 50%;
                        background: var(--cu-blue-trans);
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    span{
                        font-size: 0.875rem;
                        padding: 0.5rem 0.65rem;                        
                      }
                }
            }
        }
    }
}