.infinite-scroll-component {
    // &::-webkit-scrollbar {
    //     width: 0 !important
    //   }
    &.show-even{
        .timeline-wrapper{
          flex-direction: row-reverse !important;
          @media screen and (max-width: 550px) {
            flex-direction: column-reverse !important;
          }
        }
      
    }
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow: auto;
      .skeltonMainClass{
        position: static;
        .body{
          grid-template-columns: repeat(var(--skelton-count), 1fr) !important;
          grid-template-rows: max-content;
          .react-loading-skeleton{
            aspect-ratio: 1;
          }
          &.aspect-pt-seven-eight{
            .react-loading-skeleton{
                aspect-ratio: 1 / 0.7897;
            }  
        }
        }
      }
    }