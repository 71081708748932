.add-user-to-community {
    min-width: 100%;
    width: 50rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--cu-black-100);
    background-color: var(--cu-black-900);
    border-radius: 0.5rem;
    max-height: 93dvh;

    @media screen and (max-width: 768px) {
        width: 100dvw;
    }

    h2 {
        color: var(--cu-white-200);
    }

    tbody {
        tr {
            cursor: pointer;
        }
    }

    .active {
        background-color: var(--cu-gray-940);
        border: 1px solid var(--cu-white-200);

        td {
            border-bottom: none !important;
        }

    }

    .in-active {}
}