.asset-listing-container {
    display: grid;
    gap: 1rem;
    padding-right: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr) );
    @media screen and (max-width: 550px) {
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr) );
        padding-right: 0rem;
    }
    .react-loading-skeleton {
        aspect-ratio: 1;
    }
    .empty-box {
        aspect-ratio: 1;
        border-radius: 0.28rem;
        border: 1px solid var(--cu-black-70);
    } 
}
.asset-listing-table {
    height: 100%;
    overflow: auto;
    padding-right: 0.5rem;
    &.hide {
        display: none;
    }
}
.pieces-total-data-list{
    .assets-type-text {
        color: var(--cu-white-100);
        font-size: 0.875rem;
    }
    .search-bar{
        padding-right: 1rem;
        margin-bottom: 1rem;
        &.global_search-box-wrapper_mobile {
        .global_search-input-field{
            border: none;
            border-radius: none !important; 
            padding: 0.5rem 0.7rem !important;
        }
    }
}
}

.no-data{ &.height-unset{
min-height: unset;
}}