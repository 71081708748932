#react-crop-ui-root {
    position: fixed;
    z-index: 1001;
    .react-image-crop-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        background: rgb(0 0 0 / 56%);
        backdrop-filter: blur(1px);
    
        .react-image-crop-box {
            width: auto;
            height: auto;
            max-width: 60dvw;
            max-height: 95dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            background: var(--cu-black-700);
            border-radius: 12px;
            padding: 1rem;
            box-shadow: 0 0 0 1px var(--cu-black-40);
    
            .inline-crop-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4rem;
    
                .crop-preview-container,
                .react-crop-container {
                    width: 50%;
    
                    .label {
                        text-align: center;
                        font-size: 0.875rem;
                        color: var(--cu-white);
                        display: block;
                        height: 2rem;
                    }
    
                    .react-crop {
                        max-width: 400px;
                        max-height: 400px;
                    }
                }
    
                .react-crop-container {
                    max-height: 80vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
    
            }
    
            .actions-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2rem;
    
                .transaction-cancel, .transaction-ok {
                    font-size: 0.75rem !important;
                }
            }
        }
    
    }
}