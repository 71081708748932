.modal-outer-container {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    @keyframes slideInFromTop {
        0% {
          transform: translateY(-100%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
    }
    @keyframes slideOutFromCenter {
        0% {
            transform: translateY(0);
            opacity: 1;
          }
        100% {
          transform: translateY(100%);
          opacity: 0;
        }
    }
    
    .default_content-styles {
      background-color: var(--cu-black-700);
      border: 1px solid var(--cu-black-100);
    }
      
    .animated-slideIn {
        animation: slideInFromTop 0.5s ease-out;
    }
    .animated-slideOut {
        animation: slideOutFromCenter 0.5s ease-out;
    }
}