.nft-frame-container {
    width:100%;
    height:100%;
    position:relative;
    display:flex;
    justify-content:center;
    .nftframeskel {
      .react-loading-skeleton {
        line-height: normal !important;
      }
    }
  }
  .nft-frame-container2 {
    width:100%;
    // height:100%;
    position:relative;
    display:flex;
    justify-content:center;
    .nftframeskel {
      .react-loading-skeleton {
        line-height: normal !important;
      }
    }
  }
  
  .nft-frame-viewer {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    padding-top: 0.5px;
    //transition: height,width 0.2s ease-in-out;
  
    .framediv {
      z-index:1;
      width: 100%;
    }
  
    .frame {
      max-height: 100%;
      width: 100%;
      z-index: 1;      
  
      &.frame-detail-page {
        width: unset;
      }
    }
  
    .nft-box {
      position: absolute;
      overflow: hidden;
      width:100%;
      height:100%;
      pointer-events: auto;
      .nft {        
        left: 50%;
        top: 50%;        
        transform: translate(-50%, -50%);
        position: absolute;      
        @media screen and (max-width: 550px) { 
          height: auto;
        }
      }      
    }
  
  } 
  
  .nft-box {
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    .nft {
      max-width: 100%;
      margin: auto;
      z-index: -1;
    }
  
    video {
      width: 100%;
      height: 100%;
    }
  }
  
  .portrait {    
    .nft-box {
      .nft {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }    
  }
  
  .fillheight {    
    .nft-box {
      .nft {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }    
  }
  
  .fillwidth {    
    .nft-box {
      .nft {
        width: 100%;
        height: auto;      
      }
    }    
  }
  
  .bareimage {
    .nft-box {
      .nft {
        max-width:100%;
        object-fit:contain;
      }
    }
  }
  
  .no-frame-container {
    .nft-box {
      position: relative;
  
      .nft {
        position: relative;
        transform: none;
        left:0;
        top:0;
      }
    }  
  }
  
  .nonimage-max-size {
    .nft-frame-viewer {
      width:100% !important;
      height:100% !important;
    }
  }
  
  .nft-box-loading {
    display: none;
  }
  
  .skelcontainer {
    z-index:2;
    .react-loading-skeleton {
      line-height: normal !important;
    }
  }
  
  .nftframeskel {
    .react-loading-skeleton {
      line-height: normal;
      border-radius: 0;
    }
  }
  .skelton-loading-nft-frame {
    opacity: 0;
  }
  .data-type {
    opacity: 0;
    color: var(--cu-white-700);
    font-size: 0.875rem;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cu-black-410);
    .media-file-type{
      scale: 0.7;
      transition: all 0.5s ease;
    }
  }
  .nfthiddenimage {
    display: none !important;
  }  